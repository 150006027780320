
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import Script from "next/script";
import { AppProps } from "next/app";
import { useAuthInit, useLoginByMoodle } from "modules/Auth/authHooks";
import "../styles/globals.css";
import localFont from "@next/font/local";
import { DefaultMeta } from "modules/Meta/DefaultMeta";
import { ApiContext } from "lib/apiFetcher";
import NextNProgress from "nextjs-progressbar";
import { ToastWrapper } from "components/Toast/ToastWrapper";
import { AlgoliaContext } from "lib/algolia";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useSetUserAmplitudeWhenAuthChange } from "lib/amplitude";
import { setCookie, getCookie, getQueryStringParams, setParamsAsCookies, } from "lib/helper";
import { useAuthStore } from "modules/Auth/authStore";
import GoogleAnalytics from "modules/GoogleAnalytics/GoogleAnalytics";
import { NextPageContext } from "next";
import { IsDesktopContext, isDeviceDesktop } from "lib/device";
import { IsBusinessContext, IsTenantContext, IsGateContext, isBusinessURL, isGateURL, isTenantURL, } from "lib/host";
const CircularFont = localFont({
    src: [
        { path: "../fonts/circular-light.woff2", weight: "300" },
        { path: "../fonts/circular-medium.woff2", weight: "500" },
        { path: "../fonts/circular-black.woff2", weight: "700" },
    ],
});
const MyApp = ({ Component, pageProps, router }: AppProps) => {
    useSetUserAmplitudeWhenAuthChange();
    const loginState = useAuthStore((state) => state.loginState);
    let referral = typeof router.query.referral == "string"
        ? router.query.referral
        : getCookie("ref_code_gk") &&
            getCookie("ref_code_gk") !== "null" &&
            getCookie("ref_code_gk");
    const queries = router.query && router.query.hasOwnProperty("utm_source")
        ? Object.fromEntries(Object.entries(router.query).filter(([key, value]) => key.includes("utm")))
        : {};
    const uri = router.query &&
        ["/login", "/register"].includes(router.pathname) &&
        router.query.url
        ? router.query.url
        : null;
    // ========== Referral Code ========== //
    useEffect(() => {
        // any spesific cookie and different with current referral
        const isExistCookie = ["null", referral].every((a) => a !== getCookie("ref_code_gk"));
        // no have spesific cookie and have referral query
        const isUseRefQuery = !getCookie("ref_code_gk") && referral;
        if (loginState == "NOT_LOGIN" && referral) {
            if (isExistCookie || isUseRefQuery) {
                setCookie("ref_code_gk", referral, 7);
            }
        }
    });
    // ========== Referral Code ========== //
    // ========== UTM ========== //
    useEffect(() => {
        try {
            let queriesURI = uri
                ? //@ts-ignore
                    getQueryStringParams(decodeURIComponent(uri).split("?")[1])
                : {};
            setParamsAsCookies(queries, queriesURI);
        }
        catch (err) {
            console.log(err);
        }
    });
    // ========== UTM ========== //
    return (<>
      <NextNProgress color="#551FC1" options={{
            showSpinner: false,
        }} transformCSS={(css) => {
            return <style>{css.replaceAll("\n", "")}</style>;
        }}/>
      <div className={[CircularFont.className, "text-purple-80 font-medium"].join(" ")}>
        <IsDesktopContext.Provider value={pageProps?.isDesktop}>
          <IsTenantContext.Provider value={pageProps?.isTenant}>
            <IsBusinessContext.Provider value={pageProps?.isBusiness}>
              <IsGateContext.Provider value={pageProps?.isGate}>
                <ApiContext.Provider value={{ __apiData: pageProps?.__apiData || {} }}>
                  <AlgoliaContext.Provider value={{ __algoliaData: pageProps?.__algoliaData || {} }}>
                    <DefaultMeta withDefault={true}/>
                    <Component {...pageProps}/>
                    <ToastWrapper />
                  </AlgoliaContext.Provider>
                </ApiContext.Provider>
              </IsGateContext.Provider>
            </IsBusinessContext.Provider>
          </IsTenantContext.Provider>
        </IsDesktopContext.Provider>
      </div>
    </>);
};
MyApp.getInitialProps = ({ ctx }: {
    ctx: NextPageContext;
}) => {
    let isDesktop = false;
    let isTenant = false;
    let isBusiness = false;
    let isGate = false;
    try {
        if (ctx.req) {
            // console.log({ ua: ctx.req.headers["user-agent"] });
            isDesktop = isDeviceDesktop(ctx.req?.headers?.["user-agent"]);
            isTenant = isTenantURL(ctx.req?.headers?.["host"]);
            isBusiness = isBusinessURL(ctx.req?.headers?.["host"]);
            isGate = isGateURL(ctx.req?.headers?.["host"]);
        }
        else {
            isDesktop = isDeviceDesktop(window.navigator.userAgent);
            isTenant = isTenantURL(window.location.hostname);
            isBusiness = isBusinessURL(window.location.hostname);
            isGate = isGateURL(window.location.hostname);
        }
    }
    catch { }
    return {
        pageProps: {
            isDesktop,
            isTenant,
            isBusiness,
            isGate,
        },
    };
};
const __Next_Translate__Page__1918e802c83__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__1918e802c83__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  